/* eslint-disable complexity */
import { Grid, Stack } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { LoaderFunction } from "@remix-run/node";
import {
  json,
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useRouteError,
} from "@remix-run/react";
import { captureRemixErrorBoundaryError, withSentry } from "@sentry/remix";
import { DevTools } from "jotai-devtools";
import "jotai-devtools/styles.css";
import { TanstackQueryProvider } from "src/modules/api/TanstackQueryProvider";
import { authenticator } from "src/modules/auth/authenticator";
import { UserProvider } from "src/modules/auth/components/UserProvider";
import SharedLayout from "src/modules/layout/components/Layout";

import { MuiDocument } from "./mui/MuiDocument";
import { MuiMeta } from "./mui/MuiMeta";

import "../styles/fonts.css";
export const loader: LoaderFunction = async ({ request }) => {
  const user = await authenticator.isAuthenticated(request);

  return json({
    user,
  });
};

export function Layout({ children }: { children: React.ReactNode }) {
  return (
    <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <Meta />
        <MuiMeta />
        <Links />
      </head>
      <body>
        <UserProvider>
          <TanstackQueryProvider>
            <DevTools />
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <SharedLayout>{children}</SharedLayout>
            </LocalizationProvider>
          </TanstackQueryProvider>
        </UserProvider>
        <ScrollRestoration />
        <Scripts />
      </body>
    </html>
  );
}

function App() {
  return (
    <MuiDocument>
      <Outlet />
    </MuiDocument>
  );
}

export default withSentry(App);

interface RouterError {
  status: number;
  data: string;
  internal: boolean;
  statusText: string;
}

export const ErrorBoundary = () => {
  const error = useRouteError();

  let errorContent = <>Unknown error</>;

  captureRemixErrorBoundaryError(error);

  if (typeof error === "object" && error && "message" in error) {
    const errorObject = error as Error;
    errorContent = (
      <Stack marginTop={4}>
        <pre>{errorObject.message}</pre>
        <pre style={{ maxWidth: "600px" }}>
          {JSON.stringify(errorObject, null, 4)}
        </pre>
      </Stack>
    );
  }

  if (typeof error === "object" && error && "status" in error) {
    const errorObject = error as RouterError;
    errorContent = (
      <Stack marginTop={4}>
        <pre>
          Server Status Code: {errorObject.status} - {errorObject.statusText}
        </pre>
        <pre style={{ maxWidth: "600px" }}>{errorObject.data}</pre>
      </Stack>
    );
  }

  return (
    <Grid
      item
      xs={12}
      sm
      className="content-wrapper"
      sx={{ overflowY: "auto" }}
    >
      <h1>Error</h1>
      {errorContent}
    </Grid>
  );
};
