import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormGroup,
} from "@mui/material";

import SettingsDialogFeatureFlag from "./SettingsDialogFeatureFlag";
import {
  FeatureFlagDefinitions,
  FeatureFlagId,
} from "~/common/state/featureFlagsState";

interface SettingsDialogProps {
  open: boolean;
  onClose: () => void;
}

export default function SettingsDialog({ open, onClose }: SettingsDialogProps) {
  const featureFlags = Object.keys(FeatureFlagDefinitions) as FeatureFlagId[];

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Settings</DialogTitle>
      <DialogContent>
        <FormGroup>
          {featureFlags.map((featureFlagId) => (
            <SettingsDialogFeatureFlag key={featureFlagId} id={featureFlagId} />
          ))}
        </FormGroup>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}
