import { useContext } from "react";

import { UserContext } from "../components/UserProvider";

export const useUser = () => {
  const userContext = useContext(UserContext);

  return {
    user: userContext.user,
  };
};
