import { AppBar, Box, Stack, styled, Toolbar, Typography } from "@mui/material";
import { Link, useLocation } from "@remix-run/react";
import posthog from "posthog-js";
import { useEffect } from "react";
import { ClientOnly } from "remix-utils/client-only";
import UserAuthComponentSelector from "src/modules/auth/components/UserAuthComponentSelector";
import { useUser } from "src/modules/auth/hooks/useUser";

import TrueTractsHeader from "~/common/components/TrueTractsHeader";
import SiteConfig from "~/configuration/SiteConfig";

const StyledHeader = styled(Typography)({
  fontFamily: "monospace",
  fontSize: 20,
  fontWeight: 700,
  letterSpacing: ".3rem",
  color: "inherit",
  textDecoration: "none",
  marginLeft: "1em",
});

interface UserProfileJson {
  sub: string;
  email: string;
  picture: string;
  name: string;
  organizationName?: string | null;
}

interface UserProfile {
  profile: {
    _json: UserProfileJson;
  };
}

export default function Header() {
  const { user } = useUser() as { user: UserProfile };
  const { pathname } = useLocation();

  useEffect(() => {
    if (user) {
      // Identify sends an event, so you want may want to limit how often you call it
      posthog?.identify(user.profile._json.sub, {
        email: user.profile._json.email,
        organizationName: "True Footage",
        avatar: user.profile._json.picture,
        name: user.profile._json.name,
      });
      posthog?.group(
        "company",
        user.profile._json.organizationName ?? "True Footage",
      );
      posthog?.onFeatureFlags(function () {
        // feature flags should be available at this point
        if (posthog.isFeatureEnabled("NewCompRecPage")) {
          posthog.getFeatureFlag("NewCompRecPage");
        }
      });
    }
  }, [
    posthog,
    user?.profile._json.sub,
    user?.profile._json.email,
    user?.profile._json.organizationName,
  ]);

  return (
    <AppBar
      color="transparent"
      position="static"
      sx={{
        zIndex: "10",
        position: "relative",
        paddingX: 5,
      }}
    >
      <Toolbar disableGutters>
        <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
          <Link
            style={{ textDecoration: "none", color: "inherit" }}
            to="/truetracts"
          >
            <Stack direction="row">
              {SiteConfig.header.icon}
              <StyledHeader variant="h1">
                {SiteConfig.header.title}
              </StyledHeader>
            </Stack>
          </Link>
        </Box>
        <Box sx={{ flexGrow: 0 }}>
          <Stack direction="row" spacing={4}>
            {pathname.indexOf("/truetracts") !== -1 && user && (
              <ClientOnly>{() => <TrueTractsHeader />}</ClientOnly>
            )}

            <UserAuthComponentSelector />
          </Stack>
        </Box>
      </Toolbar>
    </AppBar>
  );
}
