import {
  Avatar,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from "@mui/material";
import { Link } from "@remix-run/react";
import React, { useState } from "react";

import { User } from "../user";
import SettingsDialog from "~/boundaries/components/Settings/SettingsDialog";

interface UserBubbleProps {
  user: User;
}

export default function UserBubble({ user }: UserBubbleProps) {
  const anchorElUser = React.useRef<HTMLElement | null>(null);

  const [userMenuOpen, setUserMenuOpen] = useState(false);
  const [settingsMenuOpen, setSettingsMenuOpen] = useState(false);
  return (
    <>
      <Tooltip title="Open user menu">
        <IconButton
          onClick={() => setUserMenuOpen((p: boolean) => !p)}
          sx={{ p: 0 }}
        >
          <Avatar
            alt={user.profile._json?.name?.toLocaleUpperCase()}
            src={user.profile._json?.picture}
          />
        </IconButton>
      </Tooltip>
      <Menu
        sx={{ mt: "45px" }}
        id="menu-appbar"
        anchorEl={anchorElUser?.current}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={userMenuOpen}
        onClose={() => setUserMenuOpen(false)}
      >
        <MenuItem key="settings" onClick={() => setSettingsMenuOpen(true)}>
          <Typography textAlign="center">Settings</Typography>
        </MenuItem>
        <MenuItem key="logout">
          <Link to={"/auth/logout"}>
            <Typography textAlign="center">Logout</Typography>
          </Link>
        </MenuItem>
      </Menu>
      <SettingsDialog
        open={settingsMenuOpen}
        onClose={() => setSettingsMenuOpen(false)}
      />
    </>
  );
}
