import { useContext } from "react";

import { UserContext } from "./UserProvider";
import LoginButton from "./LoginButton";
import UserBubble from "./UserBubble";

export default function UserAuthComponentSelector() {
  const { user } = useContext(UserContext);

  if (user == null) {
    return <LoginButton />;
  }

  return <UserBubble user={user} />;
}
