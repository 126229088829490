import { useAtomValue } from "jotai";

import BoundariesHeader from "../../boundaries/components/Header/BoundariesHeader";
import CompRecommendationHeader from "../../compRecommendation/components/Header/CompRecommendationHeader";
import {
  currentStepAtom,
  ValuationProcessStep,
} from "../state/valuationProcessState";

const empty = null;

const stepComponentMapping: Record<ValuationProcessStep, React.ReactNode> = {
  [ValuationProcessStep.Sessions]: empty,
  [ValuationProcessStep.Boundaries]: <BoundariesHeader />,
  [ValuationProcessStep.CompRecommendation]: <CompRecommendationHeader />,
  [ValuationProcessStep.CompRec]: empty,
  [ValuationProcessStep.MarketAnalysis]: empty,
  [ValuationProcessStep.Adjustments]: empty,
  [ValuationProcessStep.Reconciliation]: empty,
  [ValuationProcessStep.Export]: empty,
};

export default function TrueTractsHeader() {
  const currentStep = useAtomValue(currentStepAtom);
  return stepComponentMapping[currentStep];
}
