import { Button } from "@mui/material";
import { Form, useSubmit } from "@remix-run/react";

export default function LoginButton() {
  const submit = useSubmit();
  return (
    <Form action="/auth/login" method="post">
      <Button
        type="submit"
        onClick={(event) => submit(event.currentTarget)}
        variant="outlined"
      >
        Login
      </Button>
    </Form>
  );
}
